import Splide from "@splidejs/splide";

if (document.querySelector(".splide--hero-banner")) {
	new Splide(".splide--hero-banner", {
		type: "loop",
		arrowPath: "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
		autoplay: true,
		interval: 3000,
	}).mount();
}
